function openUserChangeForm(e){
    var $nameDiv = document.getElementById('fullNameDiv');
    var $mailDiv = document.getElementById('mailDiv');
    var $pwDiv = document.getElementById('pwDiv');

    var $nameBtn = document.getElementById('fullNameBtn');
    var $mailBtn = document.getElementById('mailBtn');
    var $pwBtn = document.getElementById('pwBtn');

    $nameDiv.style.display="none";
    $mailDiv.style.display="none";
    $pwDiv.style.display="none";

    $nameBtn.style.display="block";
    $mailBtn.style.display="block";
    $pwBtn.style.display="block";

    document.getElementById(e+"Div").style.display="flex";
    document.getElementById(e+"Btn").style.display="none";
    document.getElementById("profilePage-form").style.backgroundColor="#B8BE14";
}

function closeUserChangeForm(e){
    document.getElementById(e+"Div").style.display="none";
    document.getElementById(e+"Btn").style.display="block";
    document.getElementById("profilePage-form").style.backgroundColor="white";
}

var acc = document.getElementsByClassName("accordion");
var i;

for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function() {
        /* Toggle between adding and removing the "active" class,
        to highlight the button that controls the panel */
        let yearCate=this.innerHTML;
        nameCate = yearCate.replaceAll(' ', '-')
        const splitYear=yearCate.split(' ');
        if(splitYear.length>1){
            yearCate = splitYear[splitYear.length-1];
        }
        if(this.classList.contains('myDocs')){
            var cate='md';
        } else {
            var cate='ad';
        }
        var toShow=document.getElementById(cate+nameCate);

        this.classList.toggle("active");
        toShow.classList.toggle("active");
    });
}

document.getElementsByClassName("groupMyDocs")[0].classList.toggle("active");
//document.getElementsByClassName("groupAllDocs")[0].classList.toggle("active");
document.getElementsByClassName("myDocsList")[0].classList.toggle("active");
//document.getElementsByClassName("allDocsList")[0].classList.toggle("active");

var isEmptyYear = document.getElementsByClassName("isEmptyYear");
if(isEmptyYear.length>0){
    for(i=0; i<isEmptyYear.length; i++){
        if(isEmptyYear[i].innerHTML.trim()==""){
            isEmptyYear[i].innerHTML="<div class='toRow'><p></p><p>Aucun document trouvé pour cette période</p><p></p></div>";
        }
    }
}
